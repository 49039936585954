import { Injectable } from '@angular/core';

import { BehaviorSubject } from 'rxjs';

import { AppConfig } from 'app/app.config';

export type SignInOptions = google.IdConfiguration;
export type GoogleCredentials = google.CredentialResponse;

export type AuthErrorType = {
  error: boolean;
  type?: string;
}
@Injectable()
export class GapiService {
  private credentials: BehaviorSubject<GoogleCredentials> = new BehaviorSubject(null);
  private authErrors: BehaviorSubject<AuthErrorType> = new BehaviorSubject({ error: false });
  private googleAuthInit: BehaviorSubject<boolean> = new BehaviorSubject(false);

  public init() {
    this.initAuth2();
  }

  public signIn() {
    this.showAccountPopup();

    return this.credentials;
  }

  public signInErrors() {
    return this.authErrors;
  }

  public handleCredentialResponse(resp: GoogleCredentials) {
    this.credentials.next(resp);
    this.authErrors.next({ error: false });

    this.googleAuthInit.next(true);
  }

  public isLibraryLoadedAndInit(): BehaviorSubject<boolean> {
    return this.googleAuthInit;
  }

  private initAuth2(): void {
    google.accounts.id.initialize({
      client_id: AppConfig.googleClientId,
      callback: this.handleCredentialResponse.bind(this),
      ux_mode: 'popup',
      auto_select: true
    });
  }

  private relogin(): void {
    document.cookie = `g_state=;path=/;expires=Thu, 01 Jan 1970 00:00:01 GMT`;
    this.showAccountPopup();
  }

  private showAccountPopup() {
    google.accounts.id.prompt((notification: google.PromptMomentNotification) => {
      if (notification.isNotDisplayed()) {
        this.credentials.next(null);
        this.authErrors.next({ error: true, type: notification.getNotDisplayedReason() });
        return;
      }
      if (notification.getDismissedReason() == 'flow_restarted') {
        this.credentials.next(null);
        this.authErrors.next({ error: true });
        return;
      }
      if (notification.isSkippedMoment()) {
        this.relogin();
      }
    });
  }
}
