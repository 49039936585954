<div class="container-fluid detailed-mode-header">
  <div class="row">
    <div class="col-sm-4">
      <img src="assets/images/logo.jpg"
           class="img-responsive global-logo" alt="global payments logo">
    </div>
    <div class="col-sm-8 campaigns-scheduled-details">
      <h1>Campaigns scheduled for {{today}} - {{until}}</h1>

      <div class="position-relative">
        <h2>
          Total Campaigns
          <span class="stat-highlight">{{totalCampaigns | number}}</span>
        </h2>
        <h2>
          Total Audience
          <span class="stat-highlight">{{totalRecipients | number}}</span>
        </h2>

        <div class="btn-view-holder">
          <button class="btn btn-default btn-view" (click)="toggleView()" [disabled]="pending">
            <span *ngIf="isDefaultView; then defaultView; else extendedView"></span>
            <ng-template #defaultView>Extended View</ng-template>
            <ng-template #extendedView>
              <span class="fa fa-long-arrow-left"></span>
              Default View
            </ng-template>
          </button>

          <span
            class="tooltip-icon"
            [popover]="'The extended view of the dashboard will allow you to see up to 4 days worth of active, scheduled campaigns. Once selected, the dashboard will update to show these campaigns. To return to the default view, please select this button again.'"
            placement="left"
            triggers="mouseenter:mouseleave"
            containerClass="tooltip-md"
          >
            <i class="fa fa-question-circle" aria-hidden="true"></i>
          </span>
        </div>
      </div>
    </div>
  </div>

</div>

<app-mode-selector></app-mode-selector>

<div class="campaign-lists">
  <steps-list [steps]="enterpriseCampaignsSteps"
                  [title]="enterpriseTitle"
                  [showLogos]="true"
                  [placeholder]="placeholder"
                  [showAlert]="false"
                  [enabledSteps]="enterpriseEnabledSteps"></steps-list>
  <campaigns-list [campaigns]="emailMarketingCampaigns"
                  [title]="emailMarketingTitle"
                  [placeholder]="placeholder"
                  [showAlert]="false"
                  [enabledSteps]="emailMarketingEnabledSteps"></campaigns-list>
  <campaigns-list [campaigns]="analyticsCampaigns"
                  [title]="analyticsTitle"
                  [placeholder]="placeholder"
                  [showAlert]="false"
                  [enabledSteps]="analyticsEnabledSteps"></campaigns-list>
  <campaigns-list [campaigns]="gpSmbCampaigns"
                  [title]="gpSmbTitle"
                  [placeholder]="placeholder"
                  [showAlert]="false"
                  [showFlag]="true"
                  [enabledSteps]="gpSmbEnabledSteps"></campaigns-list>
  <campaigns-list [campaigns]="ersteJvCampaigns"
                  [title]="ersteJvTitle"
                  [placeholder]="placeholder"
                  [showAlert]="false"
                  [showFlag]="true"
                  [enabledSteps]="ersteJvEnabledSteps"></campaigns-list>
</div>
<div class="container-fluid footer">
  <p>© 2024 Global Payments Inc. All rights reserved.</p>
</div>
