import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AuthService } from 'app/services';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(
    private authService: AuthService,
    private router: Router) {}

  public canActivate(): boolean {
    if (!this.authService.isLoggedIn) {
      this.router.navigate(['/login']);
    }

    return this.authService.isLoggedIn;
  }
}
