import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { CompanySource } from 'app/types';
import { LiveCampaign } from 'app/models';
import { AppConfig } from 'app/app.config';
import { ProcService } from './proc.service';

@Injectable()
export class ProcEuService extends ProcService {
  protected readonly url = `${AppConfig.procEuUrl}dashboard/campaigns`;
  protected readonly steps_url = `${AppConfig.procEuUrl}dashboard/steps`;

  public getErsteJVCampaigns(days = 2): Observable<LiveCampaign[]> {
    return this.getCampaigns([
      AppConfig.customerTypes.HEARTLAND_ANALYTICS_CUSTOMER_TYPE_ID,
    ], days, CompanySource.ERSTE_JV);
  }

}
