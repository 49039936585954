import { Injectable } from '@angular/core';
import { environment as env} from 'environments/environment';
import * as moment from 'moment-timezone';

@Injectable()
export class DateService {
  public static readonly backendDateFormat = env.config.backendDateFormat;
  public static readonly backendTimezone = env.config.backendTimezone;

  public static isBeforeServerTime(
    date: string,
    format: string = DateService.backendDateFormat
  ): boolean {
    return moment.tz(date, format, DateService.backendTimezone).isBefore();
  }

  public static dateToIso(
    date: string,
    format: string = DateService.backendDateFormat
  ): string {
    return moment(date, format).toISOString();
  }

  public getToday(format?: string): string {
    return moment().startOf('day').format(format);
  }

  public getTomorrow(format?: string): string {
    return moment().add(1, 'day').startOf('day').format(format);
  }

  public getTodayPlusDays(days: number, format?: string) {
    return moment().add(days, 'day').startOf('day').format(format);
  }

  public backendDateHoursOffset(offset: number = 0, format?: string): string {
    return moment.tz(DateService.backendTimezone)
      .add(offset, 'hours')
      .format(format);
  }
}
