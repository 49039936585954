import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Router } from '@angular/router';

import { Observable, BehaviorSubject, ReplaySubject } from 'rxjs';
import { map, tap, switchMap, take, filter } from 'rxjs/operators';

import { AppConfig } from 'app/app.config';
import {
  AuthErrorType,
  GapiService,
  GoogleCredentials
} from './gapi.service';

interface ITokenResponse {
  token_type: string;
  access_token: string;
  expires_in: number;
}

@Injectable({
  providedIn: 'root',
})
export class AuthService implements OnDestroy {
  private readonly ACCESS_TOKEN = 'access_token';
  private readonly ACCESS_TOKEN_EXP = 'access_token_exp';
  private readonly ACCESS_TOKEN_EXP_THRESHOLD = 10;

  private readonly url = `${AppConfig.procUrl}dashboard/auth/google`;

  private destroy$: ReplaySubject<boolean> = new ReplaySubject();
  private accessToken$: BehaviorSubject<string> =
    new BehaviorSubject(this.storedToken);

  /**
   * Flag means that token is expired or missed and we started redirect to login page
   * This flag need to prevent repeated redirects to login page
   */
  private isAccessTokenExpiredFired = false;

  constructor(
    private gapiService: GapiService,
    private httpClient: HttpClient,
    private router: Router
  ) {
    this.accessToken$.next(this.storedToken);
  }

  public get accessToken(): Observable<string> {
    if (this.isAccessTokenExpired && !this.isAccessTokenExpiredFired) {
      this.logout();
      this.router.navigate(['/login']);
      this.isAccessTokenExpiredFired = true;
    }

    return this.accessToken$.asObservable().pipe(
      filter(this.isNotNull),
      take(1)
    );
  }

  public get isLoggedIn(): boolean {
    return Boolean(this.storedToken);
  }

  public get isAccessTokenExpired(): boolean {
    const timestamp = this.timestamp;
    return timestamp > this.storedTokenExp - this.ACCESS_TOKEN_EXP_THRESHOLD;
  }

  public init() {
    this.gapiService.init();
  }

  public logout() {
    this.storedToken = '';
    this.storedTokenExp = 0;
  }

  public login(): Observable<GoogleCredentials> {
    return this.gapiService.signIn().pipe(
      filter((credentials: GoogleCredentials): boolean => !!(credentials && credentials?.credential)),
      switchMap(
        (credentials: GoogleCredentials) => {
          return this.auth(credentials.credential).pipe(
            map((token: ITokenResponse) => ({ credentials, token }))
          )
        }
      ),
      tap(({ token }) => this.setAccessToken(token)),
      map(({ credentials }) => credentials)
    );
  }

  public loginErrors(): Observable<AuthErrorType> {
    return this.gapiService.signInErrors().pipe(
      filter(errorSign => errorSign.error === true)
    );
  }

  public ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  public isInit(): BehaviorSubject<boolean> {
    return this.gapiService.isLibraryLoadedAndInit();
  }

  private get storedToken(): string {
    return localStorage.getItem(this.ACCESS_TOKEN);
  }

  private set storedToken(token: string) {
    localStorage.setItem(this.ACCESS_TOKEN, token);
  }

  private get storedTokenExp(): number {
    return Number(localStorage.getItem(this.ACCESS_TOKEN_EXP));
  }

  private set storedTokenExp(expiresAt: number) {
    localStorage.setItem(this.ACCESS_TOKEN_EXP, String(expiresAt));
  }

  private setAccessToken(res: ITokenResponse): void {
    this.isAccessTokenExpiredFired = false;

    const expiresAt = this.timestamp + res.expires_in;

    this.storedToken = res.access_token;
    this.storedTokenExp = expiresAt;
    this.accessToken$.next(res.access_token);
  }

  private auth(idToken: string): Observable<ITokenResponse> {
    return this.httpClient.post<ITokenResponse>(
      this.url,
      new HttpParams().set('id_token', idToken)
    );
  }

  private get timestamp(): number {
    return Math.floor(Date.now() / 1000);
  }

  private isNotNull(value: any): boolean {
    return value !== null;
  }

}
