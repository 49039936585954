import { IEnabledSteps } from 'app/types';

interface IPresentationConfig {
  enterprise: IPresentationCampaignConfig;
  analytics: IPresentationCampaignConfig;
  emailMarketing: IPresentationCampaignConfig;
  gpSmb: IPresentationCampaignConfig;
  ersteJv: IPresentationCampaignConfig;
}

interface IPresentationCampaignConfig {
  title: string;
  steps: IEnabledSteps;
  showFlag: boolean;
}

export const presentationConfig: IPresentationConfig = {
  enterprise: {
    title: 'Xenial Customer Engagement',
    showFlag: false,
    steps: {
      offer: 'Offer',
      email: true,
      mobile: true,
      stepsCount: true,
    },
  },
  analytics: {
    title: 'Heartland Analytics',
    showFlag: false,
    steps: {
      email: true,
    },
  },
  emailMarketing: {
    title: 'Heartland POS Email Marketing',
    showFlag: false,
    steps: {
      offer: 'Coupon',
      email: true,
    },
  },
  gpSmb: {
    title: 'Global Payments Email Marketing',
    showFlag: true,
    steps: {
      email: true,
    }
  },
  ersteJv: {
    title: 'Global Payments Europe',
    showFlag: true,
    steps: {
      email: true,
    }
  }
};
