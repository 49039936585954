import { ILiveCampaign, CampaignStatus } from 'app/types';
import { DateService } from '../services/date.service';

export class LiveCampaign {
  public id: number;
  public name: string;
  public customerId: number;
  public customerName: string;
  public estimatedCount: number;
  public stepsCount: number;
  public startDate: string;
  public emailStep: boolean;
  public mobileStep: boolean;
  public offerStep: boolean;
  public status: CampaignStatus;
  public scheduleWarningLevel: number;
  public customerCountry: string;
  public customerState: string;
  public customerCity: string;

  constructor(campaign: ILiveCampaign) {
    this.id = campaign.id;
    this.name = campaign.name;
    this.customerId = campaign.customerId;
    this.customerName = campaign.customerName;
    this.estimatedCount = campaign.estimatedCount;
    this.stepsCount = campaign.stepsCount;
    this.emailStep = campaign.emailStep;
    this.mobileStep = campaign.mobileStep;
    this.offerStep = campaign.offerStep;
    this.status = campaign.status;
    this.scheduleWarningLevel = campaign.scheduleWarningLevel;
    this.customerCountry = campaign.customerCountry;
    this.customerState = campaign.customerState;
    this.customerCity = campaign.customerCity;

    this.startDate = DateService.dateToIso(campaign.startDate);
  }
}
