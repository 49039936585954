import { IAppConfig } from 'app/types';

const AppConfig: IAppConfig = {
  procUrl: 'https://dev-proc.beanstalkdata.com/',
  procEuUrl: 'https://dev-proc.eu-msg.acegp-internal.com/',
  logoUrl: 'assets/images/logos/',
  flagUrl: 'assets/images/flags/',
  procCustomerKey: '7ZN9-HW5L-ET3W-OQ83-7M1Q',
  backendTimezone: 'America/New_York',
  backendDateFormat: 'YYYY-MM-DD HH:mm:ss',
  includeTestCompanies: true,
  googleClientId: '1064309824442-2h9nv6ndq4muh7q9eqojh021pqq6l201.apps.googleusercontent.com',
  customerTypes: {
    ENTERPRISE_CUSTOMER_TYPE_ID: 3,
    QSR_CUSTOMER_TYPE_ID: 7,
    BEANSTALK_PRO_CUSTOMER_TYPE_ID: 8,
    HEARTLAND_ANALYTICS_CUSTOMER_TYPE_ID: 11,
  },
};

export const environment = {
  production: false,
  config: AppConfig,
};
