import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-preloader-icon',
  templateUrl: './preloader-icon.component.html',
  styleUrls: ['./preloader-icon.component.scss'],
})
export class PreloaderIconComponent {
  @Input() public height: number = 0;
}
