import { environment } from '../environments/environment';
import { IAppConfig } from 'app/types';

type CustomerTypes = IAppConfig['customerTypes'];

export class AppConfig {
  public static get procUrl(): string {
    return this.config.procUrl;
  }

  public static get procEuUrl(): string {
    return this.config.procEuUrl;
  }

  public static get logoUrl(): string {
    return this.config.logoUrl;
  }

  public static get procCustomerKey(): string {
    return this.config.procCustomerKey;
  }

  public static get backendTimezone(): string {
    return this.config.backendTimezone;
  }

  public static get backendDateFormat(): string {
    return this.config.backendDateFormat;
  }

  public static get includeTestCompanies(): boolean {
    return this.config.includeTestCompanies;
  }

  public static get googleClientId(): string {
    return this.config.googleClientId;
  }

  public static get customerTypes(): CustomerTypes {
    return Object.assign({}, this.config.customerTypes);
  }

  private static get config(): IAppConfig {
    return environment.config;
  }
}
