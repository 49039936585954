
import { Injectable } from '@angular/core';
import {
  HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse
} from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable()
export class LoginRedirectInterceptor implements HttpInterceptor {

  constructor(
    private router: Router
  ) {}

  public intercept(request: HttpRequest<any>, next: HttpHandler):
  Observable<HttpEvent<any>> {

    return next.handle(request).pipe(
      catchError(error => this.handleHttpError(error))
    );
  }

  private handleHttpError(error: HttpErrorResponse) {
    switch (error.status) {
      case 401:
      case 403:
        this.router.navigate(['/login']);
    }

    return throwError(error);
  }
}
