import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-preloader',
  templateUrl: './preloader.component.html',
  styleUrls: ['./preloader.style.scss'],
})
export class PreloaderComponent {
  @Input()
  public isActive: boolean = false;

  public show() {
    this.isActive = true;
  }

  public hide() {
    this.isActive = false;
  }
}
