import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';

import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { ILiveCampaignResponse, ILiveStepResponse, CompanySource } from 'app/types';
import { LiveCampaign, LiveStep } from 'app/models';
import { AppConfig } from 'app/app.config';
import { AuthService } from './auth.service';

@Injectable()
export class ProcService {
  protected readonly url = `${AppConfig.procUrl}dashboard/campaigns`;
  protected readonly steps_url = `${AppConfig.procUrl}dashboard/steps`;

  constructor(
    private http: HttpClient,
    private authService: AuthService) {}

  public getCampaigns(
    customerTypes: number[],
    days: number = 2,
    source?: string,
  ): Observable<LiveCampaign[]> {
    let params: HttpParams = this.getCampaignsParams(customerTypes)
      .append('days', String(days));

    if (source) {
      params = params.append('company_source', source);
    }

    return this.authService.accessToken.pipe(
      switchMap((token: string) => this.getCampaignsRequest(params, token)),
      map((res: ILiveCampaignResponse) => res.campaigns.map(
        campaign => new LiveCampaign(campaign)
      ))
    );
  }

  public getCampaignsSteps(
    customerTypes: number[],
    days: number = 2
  ): Observable<LiveStep[]> {
    const params: HttpParams = this.getCampaignsParams(customerTypes)
      .append('days', String(days));

    return this.authService.accessToken.pipe(
      switchMap((token: string) => this.getStepsRequest(params, token)),
      map((res: ILiveStepResponse) => res.steps.map(
        step => new LiveStep(step)
      ))
    );
  }

  public getEnterpriseCampaigns(days = 2): Observable<LiveCampaign[]> {
    return this.getCampaigns([
      AppConfig.customerTypes.ENTERPRISE_CUSTOMER_TYPE_ID,
      AppConfig.customerTypes.QSR_CUSTOMER_TYPE_ID,
    ], days);
  }

  public getEnterpriseCampaignsSteps(days = 2): Observable<LiveStep[]> {
    return this.getCampaignsSteps([
      AppConfig.customerTypes.ENTERPRISE_CUSTOMER_TYPE_ID,
      AppConfig.customerTypes.QSR_CUSTOMER_TYPE_ID,
    ], days);
  }

  public getHeartlandEmailMarketingCampaigns(
    days = 2
  ): Observable<LiveCampaign[]> {
    return this.getCampaigns([
      AppConfig.customerTypes.BEANSTALK_PRO_CUSTOMER_TYPE_ID,
    ], days);
  }

  public getHeartlandAnalyticsCampaigns(days = 2): Observable<LiveCampaign[]> {
    return this.getCampaigns([
      AppConfig.customerTypes.HEARTLAND_ANALYTICS_CUSTOMER_TYPE_ID,
    ], days, CompanySource.HEARTLAND_ANALYTICS);
  }

  public getGlobalPaymentsSMBCampaigns(days = 2): Observable<LiveCampaign[]> {
    return this.getCampaigns([
      AppConfig.customerTypes.HEARTLAND_ANALYTICS_CUSTOMER_TYPE_ID,
    ], days, CompanySource.GLOBAL_PAYMENTS_SMB);
  }

  private authHeaders(token: string, headers?: HttpHeaders): HttpHeaders {
    return (headers || new HttpHeaders())
      .append('Authorization', `Bearer ${token}`);
  }

  private getCampaignsParams(customerTypes: number[]): HttpParams {
    let params: HttpParams = new HttpParams();

    customerTypes.forEach(type => {
      params = params.append('customerType[]', String(type));
    });

    if (AppConfig.includeTestCompanies) {
      params = params.append('includeTestCompanies', '1');
    }

    return params;
  }

  private getCampaignsRequest(
    params: HttpParams,
    token: string
  ): Observable<ILiveCampaignResponse> {
    const headers = this.authHeaders(token);

    return this.http.get<ILiveCampaignResponse>(
      this.url,
      { params, headers });
  }

  private getStepsRequest(
    params: HttpParams,
    token: string
  ): Observable<ILiveStepResponse> {
    const headers = this.authHeaders(token);

    return this.http.get<ILiveStepResponse>(
      this.steps_url,
      { params, headers });
  }

}
