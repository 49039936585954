import { Component, Input } from '@angular/core';

import { IEnabledSteps } from 'app/types';
import { LiveStep } from 'app/models';
import { environment as env } from 'app/../environments/environment';
import { Observable } from 'rxjs';
import { tap, switchMap, map } from 'rxjs/operators';

const CONTINGENT = /contingent/i;

@Component({
  selector: 'steps-list',
  templateUrl: './steps-list.component.html',
  styleUrls: ['./steps-list.component.scss'],
})
export class StepsListComponent {
  @Input() public title: string;
  @Input() public perPage: number = 15;
  @Input() public pagination: Observable<number>;
  @Input() public enabledSteps: IEnabledSteps;
  @Input() public showLogos: boolean;
  @Input() public showAlert: boolean;
  @Input() public placeholder: string = `There are no campaigns scheduled`;

  @Input()
  public set steps(steps: Observable<LiveStep[]>) {
    if (!steps) {
      return;
    }

    this.initialized = false;

    this.step$ = this.pagination
      ? this.preparePaginatedSteps(steps)
      : this.prepareSteps(steps);
  }

  public initialized: boolean = false;
  public campaignCount: number = 0;
  public audienceCount: number = 0;
  public step$: Observable<LiveStep[]>;
  public isContingent: { [key: number]: boolean } = {};

  public warningsLevelTexts = {
    1: 'Notice: This campaign is scheduled at or near the same \
        time as another campaign. This may result in delayed delivery.',
    2: 'Warning: This campaign is scheduled too close to another \
        large volume campaign. Please take action.',
  };

  public getCompanyLogo(customerId: number): string {
    return `${env.config.logoUrl}${customerId}.png`;
  }

  private prepareSteps(
    steps: Observable<LiveStep[]>): Observable<LiveStep[]> {
    return steps.pipe(
      tap((c: LiveStep[]) => this.countRecipients(c)),
      tap((c: LiveStep[]) => this.findContingentSteps(c)),
      tap(() => this.initialized = true)
    );
  }

  private preparePaginatedSteps(
    steps: Observable<LiveStep[]>): Observable<LiveStep[]> {
    return this.prepareSteps(steps).pipe(
      switchMap(values => this.pagination.pipe(
        map(page => values.slice(
          this.perPage * page, this.perPage * page + this.perPage)
        )
      ))
    );
  }

  private countRecipients(steps: LiveStep[]): void {
    // Count unique campaign IDs
    this.campaignCount = (new Set(steps.map(s => s.campaignId))).size;
    this.audienceCount = steps.reduce(
      (total, step) => total + step.estimatedCount,
      0
    );
  }

  private findContingentSteps(steps: LiveStep[]): void {
    this.isContingent = {};

    steps.forEach((step: LiveStep) => {
      this.isContingent[step.id] = CONTINGENT.test(step.name);
    });
  }
}
