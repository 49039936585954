import { Component, OnDestroy, OnInit, ChangeDetectorRef } from '@angular/core';

import {
  Observable,
  ReplaySubject,
  Subject,
  UnaryFunction,
  forkJoin,
  of,
  pipe,
  from,
  BehaviorSubject,
  timer,
  throwError
} from 'rxjs';
import {
  takeUntil,
  switchMap,
  tap,
  map,
  repeat,
  delay,
  distinct,
  concatMap,
  retryWhen,
  delayWhen,
  catchError
} from 'rxjs/operators';

import { LiveCampaign } from 'app/models';
import { ProcService, DateService, LocalStorageService, ProcEuService } from 'app/services';
import { presentationConfig } from './presentation-config';

type LiveCampaigns$ = Observable<LiveCampaign[][]>;
type LiveCampaign$ = Observable<LiveCampaign[]>;
type CampaignPage$ = Observable<ICampaignPage>;

interface ICampaignPage {
  campaignType: string;
  campaigns: LiveCampaign[];
  innerPage: number;
}

enum CampaignTypes {
  enterprise = 0,
  emailMarketing,
  analytics,
  gpSmb,
  ersteJv,
}

const SECOND = 1000;
const REFRESH_PERIOD: number = SECOND * 15;
const DEFAULT_DAYS: number = 2;
const EXTENDED_DAYS: number = 4;
const STORED_DAYS_FIELD: string = 'days';
const CAMPAIGNS_PER_PAGE: number = 10;

@Component({
  selector: 'app-presentation-mode',
  templateUrl: './presentation-mode.component.html',
  styleUrls: ['./presentation-mode.component.scss'],
})
export class PresentationModeComponent implements OnInit, OnDestroy {
  public readonly campaignsPerPage: number = CAMPAIGNS_PER_PAGE;
  public readonly DATE_FORMAT: string = 'MMMM Do';
  public defaultPlaceholder: string =
    `There are no campaigns scheduled for today or tomorrow.`;
  public extendedPlaceholder: string =
    `There are no campaigns scheduled for the next 4 days.`;

  public config = presentationConfig;
  public pagination: BehaviorSubject<number> = new BehaviorSubject(0);

  public today: string = '';
  public until: string = '';
  public totalCampaigns: number = 0;
  public totalRecipients: number = 0;
  public daysCount: number = 2;
  public pending: boolean = true;
  public totalPages: number = 0;
  public currentPage: number = 0;

  public campaign$: LiveCampaign$;
  public campaignType: string = CampaignTypes[0];

  public timer$: Subject<number> = new Subject();

  private _totalPages: number = 0;
  private _currentPage: number = 0;
  private delay: number = 0;
  private destroy$: ReplaySubject<any> = new ReplaySubject(1);
  private reset$: Subject<any> = new Subject();

  constructor(private procService: ProcService,
    private procEuService: ProcEuService,
    private dateService: DateService,
    private localStorageService: LocalStorageService,
    private ref: ChangeDetectorRef,
  ) {
    this.daysCount = this.getSavedDaysValue();
  }

  public ngOnInit() {
    // Create one value observable to create a loop
    this.campaign$ = of(1).pipe(
      // Initialization
      this.pipeInit(),
      // Load campaigns
      switchMap(() => this.getCampaign$()),
      // Cancel preloader
      tap(() => this.pending = false),
      // Calculate total values
      this.pipeGetTotals(),
      // Paginate campaigns
      this.pipePaginateCampaigns(),
      // Complete the stream if requested by user (e.g. change View mode)
      takeUntil(this.reset$),
      // Start everything from the beginning when stream is completed
      repeat(),
      // Terminate everything when component is destroyed
      takeUntil(this.destroy$)
    );
  }

  public get placeholder(): string {
    return this.isDefaultView ?
      this.defaultPlaceholder :
      this.extendedPlaceholder;
  }

  public ngOnDestroy(): void {
    this.reset$.complete();
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  public toggleView(): void {
    this.delay = 0;
    this.daysCount = this.isDefaultView ? EXTENDED_DAYS : DEFAULT_DAYS;
    this.reloadCampaigns();
    this.localStorageService.set(STORED_DAYS_FIELD, this.daysCount);
  }

  public get isDefaultView(): boolean {
    return this.daysCount === DEFAULT_DAYS;
  }

  public refreshDates(): void {
    this.today = this.dateService.getToday(this.DATE_FORMAT);
    this.until = this.dateService.getTodayPlusDays(
      this.daysCount - 1,
      this.DATE_FORMAT
    );
  }

  private countCampaigns(responses: LiveCampaign[][]): number {
    return responses.reduce(
      (total, campaigns) => total + campaigns.length,
      0);
  }

  private countAllAudience(campaigns: LiveCampaign[][]): number {
    return campaigns.reduce(
      (total, campaign) => total + this.countAudience(campaign),
      0);
  }

  private countAudience(campaigns: LiveCampaign[]): number {
    return campaigns.reduce(
      (total, campaign) => total + campaign.estimatedCount,
      0);
  }

  private pipeGetTotals(): UnaryFunction<LiveCampaigns$, LiveCampaigns$> {
    return tap((res: LiveCampaign[][]) => {
      this.totalCampaigns = this.countCampaigns(res);
      this.totalRecipients = this.countAllAudience(res);
      this._totalPages = res.reduce(
        (total, campaigns) => total += (this.calcPages(campaigns) || 1), 0
      );
    });
  }

  private pipeInit() {
    return tap(() => {
      this._totalPages = 0;
      this._currentPage = 0;
      this.pending = true;
      this.refreshDates();
      this.ref.detectChanges();
    });
  }

  /**
   * Calculate amount of pages needed to display campaigns
   */
  private calcPages(campaigns: LiveCampaign[]): number {
    return Math.floor(
      (campaigns.length + CAMPAIGNS_PER_PAGE - 1) / CAMPAIGNS_PER_PAGE
    );
  }

  /**
   * Set initial values for each page
   */
  private pipeInitPage(): UnaryFunction<CampaignPage$, CampaignPage$> {
    return pipe(
      tap(page => {
        this.campaignType = page.campaignType;
        this.delay = REFRESH_PERIOD;
        this.currentPage = ++this._currentPage;
        this.totalPages = this._totalPages;

        // Reset spinner
        this.timer$.next(REFRESH_PERIOD / 1000);
      })
    );
  }

  private reloadCampaigns(): void {
    this.reset$.next(null);
  }

  /**
   * Delay each page after first one
   */
  private pipeEmitPages(): UnaryFunction<CampaignPage$, CampaignPage$> {
    return pipe(
      concatMap(campaigns => of(campaigns).pipe(delay(this.delay))),
      this.pipeInitPage()
    );
  }

  /**
   * Repeat campaigns list for each inner page, store innder page index
   *  for later use
   */
  private pipeInnerPagination(): UnaryFunction<CampaignPage$, CampaignPage$> {
    return pipe(
      switchMap(page =>
        of(page).pipe(
          repeat(Math.max(1, this.calcPages(page.campaigns))),
          map((p, i) => ({
            campaignType: p.campaignType,
            campaigns: p.campaigns,
            innerPage: i,
          }))
        )
      )
    );
  }
  private pipePaginateCampaigns(
  ): UnaryFunction<LiveCampaigns$, LiveCampaign$> {
    return pipe(
      // flatten from LiveCampaign[][] to LiveCampaign[]
      switchMap(campaigns => from(campaigns)),
      // map each list to ICampaignPage object with customer type reference
      map((campaigns, type) => {
        return {
          campaignType: CampaignTypes[type],
          campaigns,
        };
      }),
      // Split customer campaigns into pages (if necessary)
      this.pipeInnerPagination(),
      // Emit each inner page with a delay
      this.pipeEmitPages(),
      // Trigger inner pagination
      tap(page => this.pagination.next(page.innerPage)),
      // Filter values by unique customer type
      distinct(a => a.campaignType),
      // Return campaigns for the CampaignList component
      map(page => page.campaigns)
    );
  }

  private getCampaign$(): LiveCampaigns$ {
    return forkJoin([
      this.procService.getEnterpriseCampaigns(this.daysCount),
      this.procService.getHeartlandEmailMarketingCampaigns(this.daysCount),
      this.procService.getHeartlandAnalyticsCampaigns(this.daysCount),
      this.procService.getGlobalPaymentsSMBCampaigns(this.daysCount),
      this.procEuService.getErsteJVCampaigns(this.daysCount),
    ])
      .pipe(
        // Retry 3 times with 5sec delay
        retryWhen(delayWhen((err, index) => index < 2 ? timer(5000) : throwError(err))),
        catchError(error => {
          // If all attempts are failed, restart component
          this.reloadCampaigns();
          return of(error);
        })
      );
  }

  private getSavedDaysValue(): number {
    const days = Number(this.localStorageService.get(STORED_DAYS_FIELD));
    return [DEFAULT_DAYS, EXTENDED_DAYS].includes(days) ? days : DEFAULT_DAYS;
  }
}
