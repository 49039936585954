import { Component, NgZone } from '@angular/core';
import { AuthService, RouteStateService } from './services';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  constructor(
    private routeStateService: RouteStateService,
    private authService: AuthService,
    private ngZone: NgZone
  ) {
    (window as any).onGoogleLibraryLoad = () => ngZone.run(() => {
      this.authService.init();
    });
  }
}
