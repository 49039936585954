import { Component, NgZone, OnDestroy, OnInit } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';

import { BehaviorSubject, Observable, OperatorFunction, ReplaySubject, throwError, timer } from 'rxjs';
import { delayWhen, retryWhen, takeUntil } from 'rxjs/operators';

import { AuthErrorType, AuthService, RouteStateService } from 'app/services';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnDestroy, OnInit {
  public readonly ERROR_UNKNOWN = 1;
  public readonly ERROR_AUTH = 2;
  public readonly ERROR_GOOGLE_GENERAL = 3;
  public readonly ERROR_GOOGLE_NO_ACCOUNTS = 4;

  public error: BehaviorSubject<number> = new BehaviorSubject(0);
  public preloader: BehaviorSubject<boolean> = new BehaviorSubject(false);

  private destroy$: ReplaySubject<boolean> = new ReplaySubject();

  constructor(
    private authService: AuthService,
    private routeStateService: RouteStateService,
    private router: Router,
    private ngZone: NgZone
  ) {
    this.preloader.next(true);
  }

  public ngOnInit() {
    this.ngZone.run(() => {
      if (this.authService.isInit().value !== true) {
        this.authService.init();
      }
      this.preloader.next(false);
      this.login();
    });
  }

  public login() {
    this.error.next(0);

    this.authLogin();

    this.authService.loginErrors()
      .pipe(
        this.runInZone(this.ngZone),
        takeUntil(this.destroy$)
      ).subscribe(
        error => this.handleError(error)
      );
  }

  public ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private authLogin() {
    this.preloader.next(true);

    this.authService.login()
      .pipe(
        this.runInZone(this.ngZone),
        takeUntil(this.destroy$),
        // Retry 3 times and throw an error to show error message and reload component
        // retryWhen(delayWhen((err, index) => index < 2 ? timer(5000) : throwError(err)))
      ).subscribe(
        () => this.redirect(),
        error => this.handleError(error),
        () => this.preloader.next(false)
      );
  }

  private redirect() {
    const previousUrl = this.routeStateService.getPreviousUrl();
    if (!previousUrl || previousUrl === '/login') {
      this.router.navigate(['/']);
    } else {
      this.router.navigate([previousUrl]);
    }
  }

  private reload(secToWait = 10) {
    this.router.navigate([this.router.url]);
    // Wait secToWait sec to try again
    setTimeout(() => { this.authLogin() }, secToWait * 1000)
  }

  private handleError(error: any): void {
    this.preloader.next(false);
    if (error instanceof HttpErrorResponse) {
      this.handleAuthError(error);
    } else {
      this.handleGoogleError(error);
    }
  }

  private handleGoogleError(error: AuthErrorType): void {
    if (error.error) {
      switch (error.type) {
        case 'opt_out_or_no_session':
          // No active google account
          // No auto reload, need manually login into google account
          this.error.next(this.ERROR_GOOGLE_NO_ACCOUNTS);
          break;

        default:
          this.error.next(this.ERROR_GOOGLE_GENERAL);
          // Reload functionality and retry in 30 sec
          this.reload(30);
          break;
      }
    }
  }

  private handleAuthError(error: HttpErrorResponse): void {
    if (error.status === 403) {
      // It means that active google account is not from allowed domain
      // No auto reload, need manual actions from user
      this.error.next(this.ERROR_AUTH);
    } else {
      this.error.next(this.ERROR_UNKNOWN);
      this.reload();
    }
  }

  /**
   * Run to Angular zone
   * @see https://angular.io/api/core/NgZone
   */
  private runInZone<T>(zone: NgZone): OperatorFunction<T, T> {
    return (source) => {
      return new Observable(observer => {
        const onNext = (value: T) => zone.run(() => observer.next(value));
        const onError = (e: any) => zone.run(() => observer.error(e));
        const onComplete = () => zone.run(() => observer.complete());
        return source.subscribe(onNext, onError, onComplete);
      });
    };
  }
}
