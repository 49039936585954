<div class="container-fluid campaigns-summary">
  <div class="row">
    <div class="col-xs-4"><p>{{title}}</p></div>
    <div class="col-xs-4">
      <p>
        Campaigns:
        <span class="stat-highlight">{{campaignCount}}</span>
      </p>
    </div>
    <div class="col-xs-4">
      <p>
        Audience:
        <span class="stat-highlight">{{audienceCount | number}}</span>
      </p>
    </div>
  </div>
</div>
<div class="container-fluid campaign-details">
  <div class="row">
    <div class="table-responsive">
      <table class="table" *ngIf="step$ | async as steps; else preloader;">
        <thead>
        <tr>
          <th width="16%" class="customer-name">Company
          </th>
          <th>Campaign Name</th>
          <th>Step Number</th>
          <th>Step Name</th>
          <th width="225px">Execution Date and Time</th>
          <th width="120px">Audience</th>
          <th width="80px">Type</th>
          <th width="120px">Status</th>
          <th width="100px" *ngIf="showAlert">Alert</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let step of steps"
            class="alert-level-{{step.scheduleWarningLevel}} status-{{step.status}}"
            [class.alert-contingent]="isContingent[step.id]"
          >
          <td *ngIf="showLogos">
            <div class="customer-name-logo">
              <img
                [src]="getCompanyLogo(step.customerId)"
                class="img-responsive center-block"
                onerror="this.src='assets/images/logo.jpg'"
                [alt]="step.customerName"
              />
            </div>
          </td>
          <td *ngIf="!showLogos"
              class="customer-name">{{step.customerName}}</td>
          <td>{{step.campaignName}}</td>
          <td>{{step.stepNumber}} of {{step.stepCount}}</td>
          <td>{{step.name}}</td>
          <td>{{step.executionDateAndTime | date: 'MM/d/yy h:mm a'}} ET</td>
          <td>{{step.estimatedCount | number}}</td>
          <td>{{ step.type }}</td>
          <td>
            <strong class="campaign-status">
              {{ step.status === 'Completed' ? 'Executed' : step.status }}
            </strong>
          </td>
          <td *ngIf="showAlert">
            <span
              class="alert-icon"
              [popover]="warningsLevelTexts[step.scheduleWarningLevel]"
              placement="left"
              triggers="mouseenter:mouseleave"
            ></span><span
              *ngIf="isContingent[step.id]"
              class="alert-icon contingent-icon"
              [popover]="'Please note, this campaign is contingent based and should be reviewed and confirmed prior to the execution time.'"
              placement="left"
              triggers="mouseenter:mouseleave"
            ><i class="fa fa-question-circle" aria-hidden="true"></i></span>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>

<ng-template #preloader>
  <p class="no-campaigns">
    <app-preloader-icon [height]="90"></app-preloader-icon>
  </p>
</ng-template>

<p class="no-campaigns" *ngIf="initialized && campaignCount === 0">
  {{ placeholder }}
</p>
