<div class="login-wrap">
  <h1>Sign In with Google Account</h1>

  <button type="button" class="btn btn-primary" (click)="login()">Sign In</button>

  <div class="error-messages" [ngSwitch]="error | async">
    <div *ngSwitchCase="ERROR_UNKNOWN">
      An unexpected error has occurred. Please try again later.
    </div>
    <div *ngSwitchCase="ERROR_AUTH" class="incorrect-domain-google-account">
      Only xenial, e-hps and Global Payment accounts are allowed.
    </div>
    <div *ngSwitchCase="ERROR_GOOGLE_GENERAL">
        Google sign-in failed.
    </div>
    <div *ngSwitchCase="ERROR_GOOGLE_NO_ACCOUNTS" class="no-active-google-account">
      Oops! Looks like you are not currently logged on with your google account in your browser. Please login and try again.
    </div>
  </div>

  <app-preloader [isActive]="preloader | async"></app-preloader>
</div>
