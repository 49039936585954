import { Injectable } from '@angular/core';

@Injectable()
export class LocalStorageService {
  public localStorage: any;

  constructor() {
    if (!localStorage) {
      throw new Error('Current browser does not support Local Storage');
    }

    this.localStorage = localStorage;
  }

  public set(key: string, value: any): void {
    this.localStorage.setItem(key, value);
  }

  public get(key: string, def: string = null): string {
    return this.localStorage.getItem(key) || def;
  }

  public remove(key: string): any {
    this.localStorage.removeItem(key);
  }

  public setObject(key: string, value: any) {
    this.localStorage.setItem(key, JSON.stringify(value));
  }

  public getObject(key: string, def: any = null) {
    const value = this.localStorage.getItem(key);
    return (value && JSON.parse(value)) || def;
  }
}
