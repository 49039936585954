import { Component, OnDestroy, OnInit } from '@angular/core';

import { takeUntil, switchMap, tap } from 'rxjs/operators';
import {
  Observable,
  ReplaySubject,
  Subject,
  UnaryFunction,
  forkJoin,
  timer
} from 'rxjs';

import { IEnabledSteps } from 'app/types';
import { LiveCampaign } from 'app/models';
import { ProcService, DateService, LocalStorageService, ProcEuService } from 'app/services';

type LiveCampaign$ = Observable<LiveCampaign[][]>;

// minutes * (60 * 1000);
const REFRESH_PERIOD: number = 5 * (60 * 1000);
const DEFAULT_DAYS: number = 2;
const EXTENDED_DAYS: number = 4;
const STORED_DAYS_FIELD: string = 'days';

@Component({
  selector: 'app-executive-dashboard',
  templateUrl: './executive-dashboard.component.html',
  styleUrls: ['./executive-dashboard.component.scss'],
})
export class ExecutiveDashboardComponent implements OnInit, OnDestroy {
  public readonly DATE_FORMAT: string = 'MMMM Do';
  public defaultPlaceholder: string =
    `There are no campaigns scheduled for today or tomorrow.`;
  public extendedPlaceholder: string =
    `There are no campaigns scheduled for the next 4 days.`;

  public enterpriseCampaigns: Subject<LiveCampaign[]> = new Subject();
  public enterpriseEnabledSteps: IEnabledSteps = {
    offer: 'Offer',
    email: true,
    mobile: true,
    stepsCount: true,
  };
  public enterpriseTitle: string = 'Xenial Customer Engagement';
  public emailMarketingCampaigns: Subject<LiveCampaign[]> = new Subject();
  public emailMarketingEnabledSteps: IEnabledSteps = {
    offer: 'Coupon',
    email: true,
  };
  public emailMarketingTitle: string = `Heartland POS Email Marketing`;
  public analyticsCampaigns: Subject<LiveCampaign[]> = new Subject();
  public analyticsEnabledSteps: IEnabledSteps = {
    email: true,
  };
  public analyticsTitle: string = `Heartland Analytics`;

  public gpSmbCampaigns: Subject<LiveCampaign[]> = new Subject();
  public gpSmbEnabledSteps: IEnabledSteps = {
    email: true,
  };
  public gpSmbTitle: string = 'Global Payments Email Marketing';

  public ersteJvCampaigns: Subject<LiveCampaign[]> = new Subject();
  public ersteJvEnabledSteps: IEnabledSteps = {
    email: true,
  };
  public ersteJvTitle: string = 'Global Payments Europe';

  public today: string = '';
  public until: string = '';
  public totalCampaigns: number = 0;
  public totalRecipients: number = 0;
  public daysCount: number = 2;
  public pending: boolean = false;

  private destroy$: ReplaySubject<any> = new ReplaySubject(1);
  private reset$: Subject<any> = new Subject();

  constructor(private procService: ProcService,
    private procEuService: ProcEuService,
    private dateService: DateService,
    private localStorageService: LocalStorageService
  ) {
    this.daysCount = this.getSavedDaysValue();
  }

  public ngOnInit(): void {
    this.reset$.pipe(
      this.pipeInitCampaignList(),
      switchMap(() => timer(0, REFRESH_PERIOD)),
      tap(() => this.pending = true),
      tap(() => this.refreshDates()),
      switchMap(() => this.getCampaign$()),
      this.pipeGetTotals(),
      takeUntil(this.destroy$)
    ).subscribe(
      ([enterprise, analytics, emailMarketing, gpSmb, ersteJv]) => {
        this.enterpriseCampaigns.next(enterprise);
        this.analyticsCampaigns.next(analytics);
        this.emailMarketingCampaigns.next(emailMarketing);
        this.gpSmbCampaigns.next(gpSmb);
        this.ersteJvCampaigns.next(ersteJv);
        this.pending = false;
      }
    );

    this.loadCampaigns();
  }

  public get placeholder(): string {
    return this.isDefaultView ?
      this.defaultPlaceholder :
      this.extendedPlaceholder;
  }

  public ngOnDestroy(): void {
    this.reset$.complete();
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  public toggleView(): void {
    this.daysCount = this.isDefaultView ? EXTENDED_DAYS : DEFAULT_DAYS;
    this.loadCampaigns();
    this.localStorageService.set(STORED_DAYS_FIELD, this.daysCount);
  }

  public get isDefaultView(): boolean {
    return this.daysCount === DEFAULT_DAYS;
  }

  public loadCampaigns(): void {
    this.reset$.next(null);
  }

  public refreshDates(): void {
    this.today = this.dateService.getToday(this.DATE_FORMAT);
    this.until = this.dateService.getTodayPlusDays(
      this.daysCount - 1,
      this.DATE_FORMAT
    );
  }

  private countCampaigns(responses: LiveCampaign[][]): number {
    return responses.reduce(
      (total, campaigns) => total + campaigns.length,
      0);
  }

  private countAllAudience(campaigns: LiveCampaign[][]): number {
    return campaigns.reduce(
      (total, campaign) => total + this.countAudience(campaign),
      0);
  }

  private countAudience(campaigns: LiveCampaign[]): number {
    return campaigns.reduce(
      (total, campaign) => total + campaign.estimatedCount,
      0);
  }

  private pipeGetTotals(): UnaryFunction<LiveCampaign$, LiveCampaign$> {
    return tap((res: LiveCampaign[][]) => {
      this.totalCampaigns = this.countCampaigns(res);
      this.totalRecipients = this.countAllAudience(res);
    });
  }

  private pipeInitCampaignList() {
    return tap(() => {
      this.analyticsCampaigns = new Subject();
      this.emailMarketingCampaigns = new Subject();
      this.enterpriseCampaigns = new Subject();
      this.gpSmbCampaigns = new Subject();
      this.ersteJvCampaigns = new Subject();
    });
  }

  private getCampaign$(): Observable<
    [LiveCampaign[], LiveCampaign[], LiveCampaign[], LiveCampaign[], LiveCampaign[]]
  > {
    return forkJoin([
      this.procService.getEnterpriseCampaigns(this.daysCount),
      this.procService.getHeartlandAnalyticsCampaigns(this.daysCount),
      this.procService.getHeartlandEmailMarketingCampaigns(this.daysCount),
      this.procService.getGlobalPaymentsSMBCampaigns(this.daysCount),
      this.procEuService.getErsteJVCampaigns(this.daysCount),
    ]);
  }

  private getSavedDaysValue(): number {
    const days = Number(this.localStorageService.get(STORED_DAYS_FIELD));
    return [DEFAULT_DAYS, EXTENDED_DAYS].includes(days) ? days : DEFAULT_DAYS;
  }
}
