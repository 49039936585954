import { Component, Input, OnInit } from '@angular/core';
import { Observable, from, of } from 'rxjs';
import { switchMap, concatMap, delay, map } from 'rxjs/operators';

@Component({
  selector: 'app-loader-animation',
  templateUrl: './loader-animation.component.html',
  styleUrls: ['./loader-animation.component.scss'],
})
export class LoaderAnimationComponent implements OnInit {
  @Input()
  public trigger: Observable<number>;

  public timer$: Observable<string>;

  public ngOnInit() {
    this.timer$ = this.trigger.pipe(
      switchMap(n => this.timer(n)),
      map(n => `${n}s`)
    );
  }

  private timer(n: number): Observable<number> {
    return from(
      Array.from(Array(n).keys()).reverse()
    ).pipe(
      concatMap((x, i) => of(x).pipe(delay(1000 * Math.min(i, 1))))
    );
  }
}
