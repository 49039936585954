import { ILiveStep, StepStatus } from 'app/types';
import { DateService } from '../services/date.service';

export class LiveStep {
  public id: number;
  public campaignId: number;
  public name: string;
  public campaignName: string;
  public customerId: number;
  public customerName: string;
  public stepNumber: number;
  public stepCount: number;
  public executionDateAndTime: string;
  public estimatedCount: number;
  public type: string;
  public status: StepStatus;
  public scheduleWarningLevel: number;

  constructor(step: ILiveStep) {
    this.id = step.id;
    this.campaignId = step.campaignId;
    this.name = step.name;
    this.campaignName = step.campaignName;
    this.customerId = step.customerId;
    this.customerName = step.customerName;
    this.stepNumber = step.stepNumber;
    this.stepCount = step.stepCount;
    this.executionDateAndTime = DateService.dateToIso(step.executionDateAndTime);
    this.estimatedCount = step.estimatedCount;
    this.type = step.type;
    this.status = step.status;
    this.scheduleWarningLevel = step.scheduleWarningLevel;
  }
}
