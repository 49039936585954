<div class="btn-group" dropdown #dropdown="bs-dropdown" [autoClose]="true" placement="bottom right">
  <span dropdownToggle class="mode-droplet">
    <i class="fa fa-caret-down"></i>
  </span>

  <ul *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu">
    <li><a routerLink="/" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Browser Mode</a></li>
    <li><a routerLink="/presentation" routerLinkActive="active">Presentation Mode</a></li>
    <li><a routerLink="/detailed-mode" routerLinkActive="active">Detailed Mode</a></li>
  </ul>
</div>
