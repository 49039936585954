<div class="container-fluid campaigns-summary">
  <div class="row">
    <div class="col-xs-4"><p>{{title}}</p></div>
    <div class="col-xs-4">
      <p>
        Campaigns:
        <span class="stat-highlight">{{campaignCount}}</span>
      </p>
    </div>
    <div class="col-xs-4">
      <p>
        Audience:
        <span class="stat-highlight">{{audienceCount | number}}</span>
      </p>
    </div>
  </div>
</div>
<div class="container-fluid campaign-details">
  <div class="row">
    <div class="table-responsive">
      <table class="table" *ngIf="campaign$ | async as campaigns; else preloader;">
        <thead>
        <tr>
          <th width="16%" class="customer-name">Company
          </th>
          <th>Campaign Name</th>
          <th *ngIf="showFlag" width="150px" class="th-step">
            Country
          </th>
          <th class="th-step" *ngIf="enabledSteps.stepsCount" width="150px">
            <span>Step Count</span>
          </th>
          <th width="225px">Execution Date and Time</th>
          <th width="120px">Audience</th>
          <th width="80px" class="th-step">
            <span *ngIf="enabledSteps.offer">{{enabledSteps.offer}}</span>
          </th>
          <th width="80px" class="th-step">
            <span *ngIf="enabledSteps.email">Email</span>
          </th>
          <th width="80px" class="th-step">
            <span *ngIf="enabledSteps.mobile">Mobile</span>
          </th>
          <th width="120px">Status</th>
          <th width="100px" *ngIf="showAlert">Alert</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let campaign of campaigns"
            class="alert-level-{{campaign.scheduleWarningLevel}} status-{{campaign.status}}"
            [class.alert-contingent]="isContingent[campaign.id]"
          >
          <td *ngIf="showLogos">
            <div class="customer-name-logo">
              <img
                [src]="getCompanyLogo(campaign.customerId)"
                class="img-responsive center-block"
                onerror="this.src='assets/images/logo.jpg'"
                [alt]="campaign.customerName"
              />
            </div>
          </td>
          <td *ngIf="!showLogos"
              class="customer-name">{{campaign.customerName}}</td>
          <td>{{campaign.name}}</td>
          <td *ngIf="showFlag" class="td-step td-flag">
            <img
                [src]="getCompanyCountryFlag(campaign.customerCountry)"
                class="img-responsive center-block"
                [alt]="campaign.customerCountry"
                [title]="campaign.customerCountry"
              />
          </td>
          <td class="td-step" *ngIf="enabledSteps.stepsCount">
            <span *ngIf="campaign.stepsCount">{{campaign.stepsCount}}</span>
          </td>
          <td>{{campaign.startDate | date: 'MM/d/yy h:mm a'}} ET</td>
          <td>{{campaign.estimatedCount | number}}</td>
          <td class="td-step">
                <span
                  class="fa fa-check-circle fa-2x"
                  *ngIf="campaign.offerStep && enabledSteps.offer"
                ></span>
          </td>
          <td class="td-step">
                <span
                  class="fa fa-check-circle fa-2x"
                  *ngIf="campaign.emailStep && enabledSteps.email"
                ></span>
          </td>
          <td class="td-step">
                <span
                  class="fa fa-check-circle fa-2x"
                  *ngIf="campaign.mobileStep && enabledSteps.mobile"
                ></span>
          </td>
          <td>
            <strong class="campaign-status">
              {{ campaign.status === 'Completed' ? 'Executed' : campaign.status }}
            </strong>
          </td>
          <td *ngIf="showAlert">
            <span
              class="alert-icon"
              [popover]="warningsLevelTexts[campaign.scheduleWarningLevel]"
              placement="left"
              triggers="mouseenter:mouseleave"
            ></span><span
              *ngIf="isContingent[campaign.id]"
              class="alert-icon contingent-icon"
              [popover]="'Please note, this campaign is contingent based and should be reviewed and confirmed prior to the execution time.'"
              placement="left"
              triggers="mouseenter:mouseleave"
            ><i class="fa fa-question-circle" aria-hidden="true"></i></span>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>

<ng-template #preloader>
  <p class="no-campaigns">
    <app-preloader-icon [height]="90"></app-preloader-icon>
  </p>
</ng-template>

<p class="no-campaigns" *ngIf="initialized && campaignCount === 0">
  {{ placeholder }}
</p>
