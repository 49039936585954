import { Component, Input } from '@angular/core';

import { IEnabledSteps } from 'app/types';
import { LiveCampaign } from 'app/models';
import { environment as env } from 'app/../environments/environment';
import { Observable } from 'rxjs';
import { tap, switchMap, map } from 'rxjs/operators';

const CONTINGENT = /contingent/i;

@Component({
  selector: 'campaigns-list',
  templateUrl: './campaigns-list.component.html',
  styleUrls: ['./campaigns-list.component.scss'],
})
export class CampaignsListComponent {
  @Input('title') public title: string;
  @Input('perPage') public perPage: number = 15;
  @Input('pagination') public pagination: Observable<number>;
  @Input('enabledSteps') public enabledSteps: IEnabledSteps;
  @Input('showLogos') public showLogos: boolean;
  @Input('showAlert') public showAlert: boolean;
  @Input('showFlag') public showFlag: boolean = false;
  @Input('placeholder') public placeholder: string =
    `There are no campaigns scheduled`;

  @Input('campaigns')
  public set campaigns(campaigns: Observable<LiveCampaign[]>) {
    if (!campaigns) {
      return;
    }

    this.initialized = false;

    this.campaign$ = this.pagination
      ? this.preparePaginatedCampaigns(campaigns)
      : this.prepareCampaigns(campaigns);
  }

  public initialized: boolean = false;
  public campaignCount: number = 0;
  public audienceCount: number = 0;
  public campaign$: Observable<LiveCampaign[]>;
  public isContingent: { [key: number]: boolean } = {};

  public warningsLevelTexts = {
    1: 'Notice: This campaign is scheduled at or near the same \
        time as another campaign. This may result in delayed delivery.',
    2: 'Warning: This campaign is scheduled too close to another \
        large volume campaign. Please take action.',
  };

  public getCompanyLogo(customerId: number): string {
    return `${env.config.logoUrl}${customerId}.png`;
  }

  public getCompanyCountryFlag(country: string): string {
    const imageFileName = country.toLowerCase().replace(/ /g, "_");
    return `${env.config.flagUrl}${imageFileName}.png`;
  }

  private prepareCampaigns(
    campaigns: Observable<LiveCampaign[]>): Observable<LiveCampaign[]> {
    return campaigns.pipe(
      tap((c: LiveCampaign[]) => this.countRecipients(c)),
      tap((c: LiveCampaign[]) => this.findContingentCampaigns(c)),
      tap(() => this.initialized = true)
    );
  }

  private preparePaginatedCampaigns(
    campaigns: Observable<LiveCampaign[]>): Observable<LiveCampaign[]> {
    return this.prepareCampaigns(campaigns).pipe(
      switchMap(values => this.pagination.pipe(
        map(page => values.slice(
          this.perPage * page, this.perPage * page + this.perPage)
        )
      ))
    );
  }

  private countRecipients(campaigns: LiveCampaign[]): void {
    this.campaignCount = campaigns.length;
    this.audienceCount = campaigns.reduce(
      (total, campaign) => total + campaign.estimatedCount,
      0
    );
  }

  private findContingentCampaigns(campaigns: LiveCampaign[]): void {
    this.isContingent = {};

    campaigns.forEach((campaign: LiveCampaign) => {
      this.isContingent[campaign.id] = CONTINGENT.test(campaign.name);
    });
  }
}
